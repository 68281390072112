
export function c7cosmetics() {
  document.addEventListener('DOMContentLoaded', () => {
    // const accountContainer = document.getElementById('c7-account');
    const newsletterContainer = document.getElementById('c7-subscribe');
  
    // let accountModified = false;
    let newsletterModified = false;
  
    // observer
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {

        if (mutation.addedNodes.length) {
  
          // if (mutation.target.id === 'c7-account' && !accountModified) {
            
          //   const loginLink = document.querySelector('.c7-user-nav__account__login');

          //   if(loginLink) {
          //     convertIconButton(loginLink, "Log in", "profile-icon");
          //     accountModified = true;
          //   }
          // }
  
          if (mutation.target.id === 'c7-subscribe' && !newsletterModified) {

            const newsletterButton = document.querySelector('#c7-subscribe .c7-btn--primary');
            const newsletterField = document.querySelector('#c7-subscribe .c7-form__field input[type=email]')
  
            if (newsletterButton && newsletterField) {
              newsletterButton.querySelector('span')?.classList.add('sr-only');

              const icon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
              icon.setAttribute('class', 'size-5 text-white');
              icon.innerHTML = `<use fill="currentColor" xlink:href="/sprites/sprite.symbol.svg#arrow-right"></use>`;

              newsletterButton.append(icon);

              newsletterField.setAttribute('placeholder', 'Email');
              newsletterModified = true; 
            }
          }
  
          if (newsletterModified) {
            observer.disconnect();
          }
          
        }
      });
    });
  
    // if (accountContainer) {
    //   observer.observe(accountContainer, { childList: true, subtree: true });
    // }
  
    if (newsletterContainer) {
      observer.observe(newsletterContainer, { childList: true, subtree: true });
    }
  });
}


