import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import { Slider } from '@js/components/Slider.ts'
import { setupHeaderSticky } from '@js/components/header.ts'
import { c7cosmetics } from './components/commerce7';

// animations
import '@js/animations/animations.ts'

// Video facades
import '@js/components/liteYoutube.ts'
import '@js/components/liteVimeo.ts'

window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.data('slider', Slider)
Alpine.start()

setupHeaderSticky();
c7cosmetics();
