import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/all'
import { nestedLinesSplit } from '../lib/utils'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

let mm = gsap.matchMedia()

const heroImg = document.querySelector('.hero-img')
const heroCopy = document.querySelector('.hero-copy')

const pinnedImgs = document.querySelectorAll('.pinned-img')

if (heroImg) {
  mm.add('(prefers-reduced-motion: no-preference)', () => {
    ScrollTrigger.create({
      trigger: heroImg,
      pin: true,
      pinSpacing: false,
      start: 'top',
      end: 'bottom',
    })

    if (heroCopy) {
      gsap.to(heroCopy, {
        ease: 'power2.out',
        y: -100,
        scrollTrigger: {
          trigger: heroImg.parentElement,
          start: 'top',
          end: 'bottom',
          scrub: true,
        },
      })
    }

  })
}

if (pinnedImgs.length) {
  for (const item of pinnedImgs) {
    mm.add('(prefers-reduced-motion: no-preference)', () => {
      ScrollTrigger.create({
        trigger: item,
        pin: true,
        pinSpacing: false,
        start: 'top',
        end: () => 'bottom'
      })
    })
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const fadeUpElements = document.querySelectorAll(".fade-up");

  fadeUpElements.forEach((element) => {
    ScrollTrigger.create({
      trigger: element,
      start: "top 75%",
      // markers: true,
      toggleClass: { targets: element, className: "animated" },
      once: true, // do only once
    });
  });
});


document.addEventListener("DOMContentLoaded", () => {
  const fadeInElements = document.querySelectorAll(".fade-in");

  fadeInElements.forEach((element) => {
    ScrollTrigger.create({
      trigger: element,
      start: "top 80%",
      toggleClass: { targets: element, className: "animated" },
      once: true, // Only animate once
    });
  });
});

const lineAnimationEls: HTMLElement[] = gsap.utils.toArray(
  document.querySelectorAll('.lines-fade-up p'),
)

lineAnimationEls.forEach((el) => {
  // get the most nested element that contains the lines
  const split = new nestedLinesSplit(el, {
    type: 'lines',
  })

  split.lines.forEach((line) => {
    gsap.fromTo(
      line,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: line,
          start: 'top 80%',
          end: 'top 60%',
        },
      },
    )
  })
})

const introOverlay = document.getElementById("intro-overlay");

if(introOverlay) {

  mm.add('(prefers-reduced-motion: no-preference)', () => {
    
    document.addEventListener("DOMContentLoaded", () => {
      const introOverlay = document.getElementById("intro-overlay");
      const introBG = document.getElementById("intro-bg");
      const introLogo = document.getElementById("intro-logo");
      const introWelcome = document.getElementById("intro-welcome");
    
      const introTimeline = gsap.timeline({
        onComplete: () => {
          introOverlay?.remove();
        },
      });

      mm.add("(min-width: 1024px)", () => {
        
        introTimeline
          .to(introBG, {
            delay: 1,
            opacity: 0.4,
            duration: 2,
            ease: "power4.out"
          })
          .to(introWelcome, {
            y: 0,
            opacity: 1,
            duration: 1,
            ease: "power2.out"
          })
          .to(introLogo, {
            duration: 2,
            clipPath: "rect(0 100% 100% 0)",
            ease: "power1.out"
          })
          .to(introWelcome, {
            y: 300,
            scale: 0.95,
            opacity: 0,
            duration: 2,
            ease: "power2.out"
          })
          .to(introLogo, {
            y: "120%",
            scale: 0.95,
            opacity: 0,
            duration: 2,
            ease: "power2.out"
          }, "<")
          .to(introOverlay, {
            opacity: 0,
            duration: 2,
            ease: "power3.inOut",
          }, "<")
    
      });

      mm.add("(max-width: 1023px)", () => {
        
        introTimeline
          .to(introBG, {
            delay: 1,
            opacity: 0.4,
            duration: 2,
            ease: "power2.out"
          })
          .to(introWelcome, {
            y: 0,
            opacity: 1,
            duration: 1,
            ease: "power2.out"
          })
          .to(introLogo, {
            duration: 2,
            clipPath: "rect(0 100% 100% 0)",
            ease: "power2.out"
          })
          .to(introOverlay, {
            opacity: 0,
            duration: 2,
            ease: "power2.inOut",
          })

      });
    
      
    });

  })
  
}
