import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

export function setupHeaderSticky() {
  mm.add("(min-width: 768px)", () => {
    const header = document.querySelector('header') as HTMLElement & { _x_dataStack: any[] };;
    const pageNav = document.querySelector('.pagenav');

    const initialBgVal = header._x_dataStack[0].darkBg ?? true;

    if (header) {
      ScrollTrigger.create({
        trigger: '#main-content',
        start: 'top top',
        end: 'bottom top',
        // markers: true,
        onEnter: () => {
          // console.log('enter');
          header.classList.remove('relative');
          header.classList.add('bg-beige-50', 'fixed', 'top-0', 'left-0');

          if(initialBgVal) {
            header.dispatchEvent(new CustomEvent('update-dark-bg', { detail: false, bubbles: true }));
          }
          
          if(pageNav) {
            pageNav.classList.remove('relative', 'h-0', 'py-0', 'overflow-hidden');
            pageNav.classList.add('fixed', 'top-[68px]', 'left-0', 'border-y', 'h-auto', 'py-4');
          }
        },
        onLeaveBack: () => {
          // console.log('leaveback');
          header.classList.remove('bg-beige-50', 'fixed', 'top-0', 'left-0');

          if(initialBgVal) {
            header.dispatchEvent(new CustomEvent('update-dark-bg', { detail: true, bubbles: true }));
          }

          if(pageNav) {
            pageNav.classList.add('relative', 'h-0', 'py-0', 'overflow-hidden');
            pageNav.classList.remove('fixed', 'top-[68px]', 'left-0', 'border-y', 'h-auto', 'py-4');
          }
        },
      });
    }
  });
}